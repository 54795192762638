<template>
    <div>
        <v-card>
            <v-container>
                <v-row>
                    <div class="col-md-12 col-sm-12">
                        <v-toolbar  dark color="#7b7b7b">
                            <v-toolbar-title>
                                <v-row style="margin-top: 1px">
                                    <v-col cols="12" style="display: inline-flex;">
                                        <v-chip 
                                            style="margin: 0px"
                                            small
                                            :color="'info'">
                                            {{ "Escaneo de QR" }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-toolbar-title>
                        </v-toolbar>
                    </div>
                </v-row>
            </v-container>
        </v-card>
        <v-card dense>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12">
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >

                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row style="margin:auto" justify="center">
                <v-col cols="12">
                    <s-select 
                        :items="itemsLineProcess"
                        item-value="PlpId"
						item-text="PloDescription"
                        label="Seleccionar Linea en proceso"                         
                        return-object
                        v-model="objLineOut"                        
                        :slotTmp="true"
                        >
                        
                    </s-select>
                </v-col>
            </v-row> 
            <v-row>
                <v-col lg="12" class="pb-1">
                    <s-crud                      
                        :filter="filter"
						:config="config"
						
						remove
						@save="save($event)"
						searchInput
						
						title="Listado de bings"
						no-full
						height="auto"
						dense
						ref="gridPackingAssingBingToLine">
                        <template v-slot:filter>
                            <v-container>
                                <v-row justify="center" class="s-col-form">
                                    <v-col>
                                        <s-date 
                                            label="Fecha"
                                            v-model="filter.DateBegin"></s-date>
                                    </v-col>
                                    <v-col>
                                        <s-date 
                                            label="Fecha Fin"
                                            v-model="filter.DateEnd"></s-date>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:SecStatus="{ row }">
                            <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                        </template>
                    </s-crud>
                </v-col>
            </v-row>          
        </v-card>        
    </div>
   
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import _sAssingBingToLine from "@/services/FrozenProduction/FrzAssingBingToLine.js"

    export default{
        components: { sScannerQr },
        data:() => ({
            
            clearInput:false,
            configScann: {},
            itemsLineProcess: [],
            objLineOut: {},
            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            config:{
                service: _sAssingBingToLine,
                model:{
                    AbpID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus"
                },                
                headers: [
                    { text: "ID", value: "AbpID", width: 20 },					
                    { text: "Linea en proceso", value: "PloDescription", width: 20 },
                    { text: "N° Bings", value: "AbbID", width: 20 },
                    { text: "Cliente", value: "CumNombre", width: 20 },
                    { text: "Cantidad de bolsas", value: "NumberOfBags", width: 20, align: "center"},
                    { text: "Peso Neto", value: "WeightNet", width: 20, align: "center"},
                    { text: "N° Pallet", value: "SecStatus", width: 20, align: "center"},
                ],
            },
        }),
        watch:{

        },
        
        methods:{
            initialize()
            {
                _sAssingBingToLine.getlinesprocess({}, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsLineProcess = r.data;                        
                    }
                });
            },
            onValue(val)
            {
                let item = {};                
                item.PlpID = this.objLineOut.PlpID;
                
                if(item.PlpID == undefined)
                {
                    this.$fun.alert("Por Favor Seleccione linea en proceso ", "warning");
					return;
                }
                item.AbbID = val;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
                _sAssingBingToLine.save(item, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {
                        this.$refs.gridPackingAssingBingToLine.refresh();
                        this.$fun.alert("Bings ingresado correctamento", "success");
                        this.initialize();
                    }
                });
            }
        },
        created()
        {
            this.initialize();
        }
    }
</script>