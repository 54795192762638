import Service from "../Service";

const resource = "assingbingtotable/";

export default {

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    gettablesprocess(abt, requestID){
        return Service.post(resource + "gettablesprocess", {},{
            params: {requestID: requestID},
        });
    },

    
}