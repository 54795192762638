<template>
    <div>
        <v-card>
            <v-container>
                <v-row>
                    <div class="col-md-12 col-sm-12">
                        <v-toolbar  dark color="#7b7b7b">
                            <v-toolbar-title>
                                <v-row style="margin-top: 1px">
                                    <v-col cols="12" style="display: inline-flex;">
                                        <v-chip 
                                            style="margin: 0px"
                                            small
                                            :color="'info'">
                                            {{ "Escaneo de QR" }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-toolbar-title>
                        </v-toolbar>
                    </div>
                </v-row>
            </v-container>
        </v-card>
        <v-card dense>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12">
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >

                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row style="margin:auto" justify="center">
                <v-col cols="12">                    
                    <v-item-group v-model="selectStation"  multiple active-class="primary">
                        <div class="text-caption mb-2">Estaciones de trabajo</div>
                            <v-container>
                                <v-row >
                                    <v-col 
                                        class="fm-card-container"
                                        cols="auto"
                                        v-for="item in itemsStatiobWorkProcess"
                                            v-bind:key="item.PwpID"
                                            :value="item">
                                        <v-item 
                                            v-slot="{ active, toggle }"
                                            >
                                            
                                                <v-chip                                         
                                                    :color="active ? 'primary' : ''" @click="toggle"> 
                                                    {{ item.PwsDescription.toLocaleUpperCase() }} 
                                                </v-chip>
                                        </v-item>  
                                    </v-col>
                                </v-row>
                            </v-container>     
                    </v-item-group>
                </v-col>
            </v-row> 
            <v-row>
                <v-col lg="12" class="pb-1">
                    <s-crud                      
                        :filter="filter"
						:config="config"
						
						remove
						@save="save($event)"
						searchInput
						
						title="Listado de bings"
						no-full
						height="auto"
						dense
						ref="gridPackingAssingBingToTable">
                        <template v-slot:filter>
                            <v-container>
                                <v-row justify="center" class="s-col-form">
                                    <v-col>
                                        <s-date 
                                            label="Fecha"
                                            v-model="filter.DateBegin"></s-date>
                                    </v-col>
                                    <v-col>
                                        <s-date 
                                            label="Fecha Fin"
                                            v-model="filter.DateEnd"></s-date>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:SecStatus="{ row }">
                            <v-icon :color="row.SecStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                        </template>
                    </s-crud>
                </v-col>
            </v-row>          
        </v-card>        
    </div>
   
</template>


<script>
    import _sAssingBingToTable from "@/services/FrozenProduction/FrzAssingBingToTable.js"
    import _sServiceTableProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js"
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    export default{
        data()
        {
            return{
                selectStation: [],
                clearInput:false,
                configScann: {},
                filter: {},
                config: {
                    service: _sAssingBingToTable,
                    model:{
                        AbpID: "ID",
                        DateBegin: "date",
                        DateEnd: "date",
                        SecStatus: "SecStatus"
                    }, 
                    headers: [
                        { text: "ID", value: "PabID", width: 20 },		
                        { text: "N° Bings", value: "AbbID", width: 20 },
                        { text: "Estacion de Trabajo", value: "PwsDescription", width: 20 },
                        { text: "Cliente", value: "CumNombre", width: 20 },                        
                        { text: "N° Pallet", value: "SecStatus", width: 20, align: "center"},
                    ],
                },
                itemsStatiobWorkProcess: [],
                objStationWorkOut: {},
                itemTurn: {},
                TrnID: 0,
                
            }
        },
        methods: {
            
            getStationWorkProcess(TrnID){
                _sServiceTableProcess.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {                        
                        this.itemsStatiobWorkProcess = r.data;   
                        console.log(this.itemsStatiobWorkProcess);                  
                    }
                })
            },
            TurnActive(){
            let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;   
                        this.TrnID = this.itemTurn[0].TrnID;
                        this.getStationWorkProcess(this.TrnID);                                
                    }
                });      
            },
            onValue(val)
            {
                
                let stationWork = []
                this.selectStation.forEach(element => {
                    stationWork.push(this.itemsStatiobWorkProcess[element]);
                });

                let jsonstationWork = []

                stationWork.forEach(element => {
                    jsonstationWork.push(
                        {
                            PwpID : element.PwpID,
                            PwsDescription : element.PwsDescription
                        }
                    );        
                });

                let stringstationWork = {}
                stringstationWork = JSON.stringify(jsonstationWork);
                
                let item = {};
                item.PwpID = this.objStationWorkOut.PwpID;
                
                item.jsonPwpID = stringstationWork;
                item.AbbID = val;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
                
                _sAssingBingToTable.save(item, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {
                        this.$refs.gridPackingAssingBingToTable.refresh();
                        this.$fun.alert("Bings ingresado correctamento", "success");
                        this.TurnActive();
                    }
                });
            }
        },
        created(){
            this.TurnActive();
        },
       
    }
</script>