<template>
    <v-card dense>
        <v-row style="margin-top: 4px;">
            <v-col cols="12" lg="12" md="12">
                <v-tabs v-model="currentItem">                    
                    <v-tab href="#EntryTable">Ingreso a Estacion en Proceso</v-tab>
                    <v-tab href="#EntryLine">Ingreso a Linea</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentItem">                    
                    <v-tab-item :value="'EntryTable'">
                        <entry-table></entry-table>
                    </v-tab-item>
                    <v-tab-item :value="'EntryLine'">
                        <entry-line></entry-line>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import EntryLine from './FrzAssingBingToLine.vue';
    import EntryTable from './FrzPackingAssingBingToTable.vue';

    export default{
        components: {EntryLine, EntryTable},
        data(){
            return{
                currentItem: "tab-Funciones",
            }
        }
    }
</script>